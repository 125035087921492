import React, { useContext, useState, useEffect } from 'react'
import { navigate } from 'gatsby'

import LayoutContext from '../../context/LayoutContext'
import { useWindowDimensions, useSiteMetadata } from '../../utils/hooks'
import { Container } from '../../utils/styles'
import MobileNav from './MobileNav'
import HamburgerIcon from './HamburgerIcon'
import CloseIcon from './CloseIcon'
import Logo from './Logo'
import { breakpoints } from '../../utils/styles'
import { colors, space } from '../../utils/theme'
import {
  OuterWrapper,
  InnerWrapper,
  MenuBar,
  MenuItem,
  MenuItemExternal,
  LogoWrapper,
  MenuItemWrapper,
} from './styles'

const Navigation = ({ color, logoVisible = true }) => {
  const { mobileNavVisible, toggleMobileNav, setMobileNavVisible } =
    useContext(LayoutContext)
  const { width } = useWindowDimensions()
  const { primaryNav } = useSiteMetadata()

  return (
    <>
      <MobileNav visible={mobileNavVisible} />
      <OuterWrapper>
        <Container>
          <InnerWrapper>
            <LogoWrapper>
              {mobileNavVisible ? (
                <CloseIcon onClick={toggleMobileNav} />
              ) : (
                <HamburgerIcon onClick={toggleMobileNav} />
              )}
              <Logo
                onClick={() => {
                  setMobileNavVisible(false)
                  navigate('/')
                }}
                logoVisible={logoVisible}
                height={width > breakpoints.l ? space[16] : space[8]}
              />
            </LogoWrapper>
            <MenuBar>
              {primaryNav
                ? primaryNav.map(({ name, link }, i) => {
                    return (
                      <MenuItemWrapper key={name}>
                        {link.includes('https') ? (
                          <MenuItemExternal
                            href={link}
                            target="_blank"
                            rel="noreferrer"
                            color={colors.hergaBlue}
                          >
                            {name}
                          </MenuItemExternal>
                        ) : (
                          <MenuItem to={link} color={colors.hergaBlue}>
                            {name}
                          </MenuItem>
                        )}
                      </MenuItemWrapper>
                    )
                  })
                : null}
            </MenuBar>
          </InnerWrapper>
        </Container>
      </OuterWrapper>
    </>
  )
}

export default Navigation
