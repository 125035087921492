import * as React from 'react'
import PropTypes from 'prop-types'

import Navigation from '../../components/Navigation'
import { Container } from '../../utils/styles'
import Footer from '../../components/Footer'
import Layout from '../../layouts'

const Page = ({ children, useContainer = true }) => {
  return (
    <Layout>
      <Navigation />
      {useContainer ? (
        <Container>
          <main>{children}</main>
        </Container>
      ) : (
        <main>{children}</main>
      )}
      <Footer />
    </Layout>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Page
