import styled from '@emotion/styled'
import { fontSizes, space } from '../../../utils/theme'

export const Wrapper = styled.div`
  visibility: ${({ visible }) => (visible ? 'block' : 'hidden')};
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ visible }) => (visible ? '100%' : '0')};
  height: 100vh;
  background-color: white;
  overflow-x: hidden;
  white-space: nowrap;
  transition: 0.5s;
`

export const ItemsList = styled.ul`
  list-style-type: none;
  margin: ${space[20]} 0;
  padding: 0;
`

export const PrimaryNavItem = styled.li`
  font-weight: 600;
  line-height: 1.6;
  font-size: ${fontSizes.md};
  margin-bottom: 0;
  padding: 15px ${space[5]} 15px;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: ${space[5]};
    right: ${space[5]};
    border-bottom: 1px solid #343535;
  }
`

export const SecondaryNavItem = styled.li`
  font-weight: 400;
  font-size: ${fontSizes.sm};
  margin-bottom: 0;
  padding: 15px ${space[5]} 15px;
  position: relative;
  cursor: pointer;
`
