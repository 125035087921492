import React from 'react'

import { LogoWrapepr } from './styles'

const Logo = ({ color, height = 32, onClick, logoVisible }) => (
  <LogoWrapepr onClick={onClick} logoVisible={logoVisible}>
    <svg x="0px" y="0px" height={height} viewBox="0 0 3716.2 1000">
      <rect x="1071.93" width="2644.24" height="1000" fill="#1a1f52" />
      <g id="Konstruktionsbüro">
        <path
          fill="#fff"
          d="M1211.41,361.15V119h56.83v96.88h.65l42-96.88H1369l-51.67,106.89,54.9,135.3h-60.06l-31.33-84.28-12.59,24.54v59.74Z"
        />
        <path
          fill="#fff"
          d="M1390.29,237.15c0-46.5,27.44-62,67.17-62,45.2,0,67.16,21.31,67.16,62v73.62c-2.59,41-28.1,56.19-67.16,56.19-36.82,0-67.17-14.85-67.17-56.19ZM1441.63,314c1,10.34,5.17,15.83,15.83,15.83s14.85-5.49,15.82-15.83V233.27c0-11.62-3.88-21-15.82-21s-15.83,9.36-15.83,21Z"
        />
        <path
          fill="#fff"
          d="M1601.78,180.32v22.6h.65c4.84-13.88,19.7-27.77,35.84-27.77,24.22,0,45.86,13.24,45.86,37.78V361.15h-51.35V232c-.64-8.39-3.87-15.5-13.56-14.85-9,.32-13.88,5.49-13.88,14.85V361.15H1554V180.32Z"
        />
        <path
          fill="#fff"
          d="M1788.41,233.92c-.64-6.14-.64-12.27-2.9-17.11-1.94-4.52-5.82-7.75-14.21-7.75-10,0-17.12,4.52-17.12,15.17,0,9.69,8.08,14.53,15.83,18.41,33.26,15.18,69.1,23.9,69.1,66.84,0,40.37-30.68,57.48-68.45,57.48-40.69,0-69.11-17.44-67.17-61h49.08c-.32,6.14.32,12.92,3.23,18.41,2.58,5.16,7.43,8.72,15.17,8.72,11,0,18.41-5.49,18.41-17.12a17.59,17.59,0,0,0-6.78-13.88c-20.66-11-49.41-18.73-65.55-37.14-8.72-10-12.92-20.67-12.92-33.91,0-39.07,31-55.86,66.84-55.86,37.46,0,67.5,18.73,65.56,58.77Z"
        />
        <path
          fill="#fff"
          d="M1846.84,217.45V180.32h20.35V128.65h51.34v51.67h24.54v37.13h-24.54v95.26c0,10.34,4.52,12.6,11.3,12.6,5.82-1,7.75-.65,13.24-.65v36.17c-11,1.61-21.64,2.9-32.93,2.9-27.77,0-43-14.21-43-41.65V217.45Z"
        />
        <path
          d="M2011.84,180.32v27.44h.65c5.81-19.37,18.4-32.61,39.71-32.61v58.12a83.78,83.78,0,0,0-15.49-1.29c-14.54,0-20.35,2.91-21.31,17.76V361.15h-51.35V180.32Z"
          fill="#fff"
        />
        <path
          d="M2155.2,361.15V338.87h-.64c-4.84,13.88-19.7,28.09-35.85,28.09-29.7,0-45.85-13.56-45.85-38.1V180.32h51.35V309.8c.64,8.4,2.9,15.83,12.59,15.18,9-.32,14.85-5.81,14.85-15.18V180.32H2203V361.15Z"
          fill="#fff"
        />
        <path
          d="M2232.37,361.15V119h51.34V240.7h.65l35.2-60.38h56.18l-51,72.65,51.67,108.18h-53.93l-30-69.75-8.77,11.6v58.12Z"
          fill="#fff"
        />
        <path
          d="M2384.77,217.45V180.32h20.34V128.65h51.35v51.67H2481v37.13h-24.54v95.26c0,10.34,4.52,12.6,11.3,12.6,5.81-1,7.75-.65,13.24-.65v36.17c-11,1.61-21.64,2.9-32.94,2.9-27.77,0-42.95-14.21-42.95-41.65V217.45Z"
          fill="#fff"
        />
        <path
          d="M2507.79,162.56V119h51.34v43.6Zm0,198.59V180.32h51.34V361.15Z"
          fill="#fff"
        />
        <path
          d="M2591.74,237.15c0-46.5,27.44-62,67.16-62,45.21,0,67.17,21.31,67.17,62v73.62c-2.59,41-28.1,56.19-67.17,56.19-36.81,0-67.16-14.85-67.16-56.19ZM2643.08,314c1,10.34,5.16,15.83,15.82,15.83s14.85-5.49,15.83-15.83V233.27c0-11.62-3.88-21-15.83-21s-15.82,9.36-15.82,21Z"
          fill="#fff"
        />
        <path
          d="M2803.23,180.32v22.6h.65c4.84-13.88,19.7-27.77,35.84-27.77,24.22,0,45.85,13.24,45.85,37.78V361.15h-51.34V232c-.64-8.39-3.88-15.5-13.56-14.85-9,.32-13.89,5.49-13.89,14.85V361.15h-51.34V180.32Z"
          fill="#fff"
        />
        <path
          d="M2989.86,233.92c-.65-6.14-.65-12.27-2.91-17.11-1.93-4.52-5.81-7.75-14.2-7.75-10,0-17.12,4.52-17.12,15.17,0,9.69,8.07,14.53,15.83,18.41,33.26,15.18,69.1,23.9,69.1,66.84,0,40.37-30.68,57.48-68.46,57.48-40.69,0-69.1-17.44-67.16-61H2954c-.32,6.14.32,12.92,3.23,18.41,2.58,5.16,7.43,8.72,15.17,8.72,11,0,18.41-5.49,18.41-17.12a17.59,17.59,0,0,0-6.78-13.88c-20.67-11-49.41-18.73-65.55-37.14-8.72-10-12.92-20.67-12.92-33.91,0-39.07,31-55.86,66.84-55.86,37.46,0,67.49,18.73,65.55,58.77Z"
          fill="#fff"
        />
        <path
          d="M3111.26,195.17h.64c8.08-15.5,23.58-20,38.43-20,33.91,0,43.92,33.58,43.92,62V299.8c0,30.67-7.43,65.87-44.88,67.16a47.62,47.62,0,0,1-41-26.48h-.64v20.67h-47.79V119h51.34Zm31.65,51.67c0-10.66,0-29.39-14.86-29.39-13.88,0-16.79,14.21-16.79,31.65v42.62c0,15.5,3.23,33.59,15.82,33.26,16.47,0,15.83-19.69,15.83-31.32Z"
          fill="#fff"
        />
        <path
          d="M3303.06,361.15V338.87h-.65c-4.84,13.88-19.7,28.09-35.84,28.09-29.71,0-45.86-13.56-45.86-38.1V180.32h51.35V309.8c.64,8.4,2.9,15.83,12.59,15.18,9-.32,14.86-5.81,14.86-15.18V180.32h51.34V361.15ZM3277.55,112.5v42h-51v-42Zm18.73,0h51v42h-51Z"
          fill="#fff"
        />
        <path
          d="M3428.66,180.32v27.44h.64c5.81-19.37,18.41-32.61,39.72-32.61v58.12a83.89,83.89,0,0,0-15.5-1.29c-14.53,0-20.34,2.91-21.31,17.76V361.15h-51.34V180.32Z"
          fill="#fff"
        />
        <path
          d="M3488.39,237.15c0-46.5,27.44-62,67.16-62,45.21,0,67.17,21.31,67.17,62v73.62c-2.59,41-28.1,56.19-67.17,56.19-36.81,0-67.16-14.85-67.16-56.19ZM3539.73,314c1,10.34,5.16,15.83,15.82,15.83s14.85-5.49,15.83-15.83V233.27c0-11.62-3.88-21-15.83-21s-15.82,9.36-15.82,21Z"
          fill="#fff"
        />
      </g>
      <g id="Herga">
        <path
          d="M1211.41,838.61v-400h93.86V592.22h65.06V438.63h93.86v400h-93.86V672.22h-65.06V838.61Z"
          fill="#fff"
        />
        <path
          d="M1591.11,703.15v52.26c0,16.54,7.47,30.4,26.13,31.47,31.47,1.6,28.27-26.67,29.87-51.73h84.26c4.27,76.79-37.33,112-110.93,113.06-60.79.53-114.12-24.53-114.12-92.8V633.82c0-76.79,48.53-101.86,114.12-102.39,59.2-.54,114.13,21.86,114.13,86.39v85.33Zm58.67-56V617.82c0-10.66-6.94-30.4-28.8-30.4-24,0-29.87,19.2-29.87,40v19.73Z"
          fill="#fff"
        />
        <path
          d="M1854,540v45.33h1.07c9.6-32,30.4-53.86,65.6-53.86v96a138.18,138.18,0,0,0-25.6-2.13c-24,0-33.6,4.8-35.2,29.33v184H1775.1V540Z"
          fill="#fff"
        />
        <path
          d="M2034.27,855.14c0,20.8,3.2,29.87,27.73,28.27,17.6-1.6,25.07-5.34,25.6-24.54V798.61h-1.07c-17.06,19.73-37.33,32.53-60.26,32.53-53.33,0-74.66-48-74.66-93.86V633.82c0-46.93,17.07-102.39,73.06-102.39,29.33,1.06,52.8,14.93,67.2,44.79h1.07V540h78.92V844.47c0,69.87-50.13,94.93-113.59,94.93-64,0-106.66-15.46-108.79-84.26Zm2.13-125.32c0,16.53,5.34,32,25.07,32,22.93,0,25.6-20.8,25.6-38.93V640.22c0-20.27-4.8-39.46-27.73-39.46-21.87,0-23.47,28.79-22.94,44.26Z"
          fill="#fff"
        />
        <path
          d="M2360.65,811.94c-21.34,23.47-39.47,36.27-73.07,36.27-48,0-77.86-32.53-77.86-80,0-67.73,36.8-89.06,74.13-101.33,36.8-12.26,73.6-16,73.6-48,0-16.53-9.6-30.93-26.67-30.93-30.93,0-30.93,21.33-32.53,46.39h-84.79c-1.61-37.33,9.6-62.93,30.39-79.46s50.13-23.46,84.26-23.46c83.73,0,114.13,25.6,114.13,91.19V805.54c0,11.2,3.2,22.4,6.93,33.07h-82.66Zm-3.2-118.39c-17.07,9.07-32,13.87-42.67,21.87-10.66,7.46-19.73,18.13-19.73,38.39,0,18.67,11.2,32,31.47,32,25.06,0,30.39-26.13,30.93-45.33Z"
          fill="#fff"
        />
      </g>
      <g>
        <rect width="1000" height="1000" fill="#1a1f52" />
        <polygon
          id="H"
          points="116 124 332 124 332 388 464 388 464 592 332 592 332 856 116 856 116 124"
          fill="#fff"
        />
        <polygon
          id="K"
          points="541.82 124 541.82 856 889.82 856 661.82 500 889.82 124 541.82 124"
          fill="#fff"
        />
      </g>
    </svg>
  </LogoWrapepr>
)

export default Logo
