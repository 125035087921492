import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { breakpoints } from '../../utils/styles'
import { colors, fontSizes, space } from '../../utils/theme'

export const OuterWrapper = styled.div`
  background-color: white;
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  z-index: 40;
  border-bottom: 2px solid ${colors.hergaBlue};
`

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const MenuBar = styled.div`
  position: relative;
  z-index: 199;
  display: flex;
  justify-content: space-around;
  align-items: center;
  animation: 1s ease-out 0s 1 slideInFromTop;

  @keyframes slideInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const LogoWrapper = styled.div`
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 20;

  @media (min-width: ${breakpoints.l}px) {
    justify-content: flex-start;
    display: block;
  }

  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
`

export const MenuItemWrapper = styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`

export const MenuItem = styled(Link)`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  color: black;
  font-size: ${fontSizes.lg};
  font-weight: 600;
  text-align: center;
  color: ${({ color }) => (color ? color : 'black')};
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-left: ${space[5]};
  margin-right: ${space[5]};
  text-transform: uppercase;
  flex-direction: column;
  justify-content: center;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    background: ${colors.hergaBlue};
    transition: transform 0.3s;
    transform-origin: left top;
    transform: scale(1, 0);
  }

  &:hover {
    color: #fff;
  }

  &:hover::before {
    transform-origin: left bottom;
    transform: scale(1, 1);
  }
`

export const MenuItemExternal = styled.a`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  color: black;
  font-size: ${fontSizes.lg};
  font-weight: 600;
  text-align: center;
  color: ${({ color }) => (color ? color : 'black')};
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-left: ${space[5]};
  margin-right: ${space[5]};
  text-transform: uppercase;
  flex-direction: column;
  justify-content: center;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: '';
    background: ${colors.hergaBlue};
    transition: transform 0.3s;
    transform-origin: left top;
    transform: scale(1, 0);
  }

  &:hover {
    color: #fff;
  }

  &:hover::before {
    transform-origin: left bottom;
    transform: scale(1, 1);
  }
`

export const Line = styled.hr`
  width: 100%;
  border: 0.5pt solid ${({ color }) => (color ? color : 'black')};
  background-color: black;

  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const LogoWrapepr = styled.span`
  position: relative;
  z-index: 199;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  opacity: ${({ logoVisible }) => (logoVisible ? '1' : '0')};

  animation: 1s ease-out 0s 1 slideInFromTop;

  @keyframes slideInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: ${breakpoints.l}px) {
    margin-left: auto;
    margin-right: auto;
  }
`

export const HamburgerWrapper = styled.span`
  z-index: 200;
  cursor: pointer;

  @media (min-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
`

export const GridRight = styled.div`
  grid-area: right;
`

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
`
