import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

import { useSiteMetadata } from '../../utils/hooks'
import { Container } from '../../utils/styles'
import { colors } from '../../utils/theme'
import { Wrapper, Grid, Links, Item } from './styles'

const Footer = ({ color = colors.darkGrey }) => {
  const { title, secondaryNav } = useSiteMetadata()

  return (
    <Wrapper>
      <Container>
        <Grid>
          <Links style={{ color: color }}>
            © {new Date().getFullYear()} {title}
            {secondaryNav.map(({ name, link }) => (
              <Item to={link} key={name} color={color}>
                {name}
              </Item>
            ))}
          </Links>

          <Links style={{ color: color }}>
            Konzept &amp; Entwicklung:
            <a style={{ color: color }} href="https://wertgebung.de">
              WERTGEBUNG
            </a>
          </Links>
          <StaticImage
            src="../../images/universal-robots-logo.png"
            layout="fullWidth"
            alt="Universal Robots Logo"
            placeholder="tracedSVG"
          />
        </Grid>
      </Container>
    </Wrapper>
  )
}

export default Footer
